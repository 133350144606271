import Head from 'next/head';
import {
  useStoryblokState,
  StoryblokComponent,
} from '@storyblok/react';
import { usePageviewTracking } from '../client-event-tracking/hooks';
import { fetchStory } from '../upstreams/storyblok';
import cookies from 'js-cookie';
import { useRouter } from 'next/router';
import setResponseHeaders from '@buzzfeed/headers';

export default function Page({ story, site }) {
  story = useStoryblokState(story);
  const router = useRouter();

  const contextPageId = story?.full_slug?.match(/^static-pages\/(buzzfeed\/app|tasty\/download|huffpost\/download)/) ? 'app_promo' : story?.slug;

  usePageviewTracking(story, {
    // eslint-disable-next-line camelcase
    context_page_type: 'utility',
    // eslint-disable-next-line camelcase
    context_page_id: contextPageId,
    destination: site,
    source: `web_${site === 'buzzfeed' ? 'bf' : site}`,
    // eslint-disable-next-line camelcase
    page_edition: 'en-us'
  });

  const favicons = {
    buzzfeed: 'https://www.buzzfeed.com/obiwan-static/images/favicon.ico?v=0.458219009885',
    tasty: 'https://tasty.co/static-assets/tasty-ui/_next/static/images/favicon-28da50f156ed9dd557d5a773675f8b38.ico',
    huffpost: 'https://www.huffpost.com/favicon.ico',
  }

  // Check if the 'country' cookie exists
  let countryCookie = cookies.get('country');
  if (countryCookie === 'ja-jp') {
    countryCookie = 'ja';
  } else if (countryCookie === 'mx') {
    countryCookie = 'es-mx';
  }

  const { edition: currentEdition } = router.query;
  if (countryCookie && currentEdition !== countryCookie) {
    // Add the 'country' URL parameter to the current URL
    const url = new URL(window.location.href);
    url.searchParams.set('edition', countryCookie);
    router.push(url.toString());
  }

  return (
    <>
      <Head>
        <title>{story ? story.name : site}</title>
        <link rel="icon" href={favicons[site]} />
      </Head>
      <StoryblokComponent blok={story?.content} site={site} className={`${site}-container`} />
    </>
  );
}


export async function getServerSideProps({ res, req, params, query }) {
  const edition = query.edition || 'en';
  const site = params.slug[1];
  let slug = params.slug ? params.slug.join('/') : 'home';

  let data = await fetchStory(slug, edition);
  setResponseHeaders({
    req,
    res,
    maxAge: 86400,
    contentIDs: [
      { type: 'page', id: `${site}-${slug}` },
    ],
  });

  return {
    props: {
      story: data ? data.story : false,
      key: data ? data.story.id : false,
      site: site,
    },
  };
}
